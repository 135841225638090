<template>
  <van-nav-bar safe-area-inset-top fixed placeholder>
    <template #left>
      <img class="logo" src="../assets/logo.png" />
    </template>
    <template #right>
      <router-link v-if="search" to="search">
        <img class="search" src="../assets/search-black.png"
      /></router-link>
      <span class="time" v-else>{{ nowTime }}</span>
    </template>
  </van-nav-bar>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import utils from "@/utils/utils";
import { NavBar } from "vant";
export default defineComponent({
  name: "Navbar",
  props: {
    search: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [NavBar.name]: NavBar,
  },
  setup() {
    const logoPic = "../assets/logo.png";
    var nowTime = utils.setNowTimes();

    return { logoPic, nowTime };
  },
});
</script>
<style lang="scss" scoped>
.logo {
  height: 28px;
}

.time {
  font-family: DIN-Bold;
  font-weight: bold;
}

.search {
  width: 17px;
  height: 17px;
}
</style>
