function setNowTimes(): string {
  const myDate = new Date();
  const wk = myDate.getDay();
  const yy = String(myDate.getFullYear());
  const mm = myDate.getMonth() + 1;
  const dd = String(
    myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate()
  );
  const weeks = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  const week = weeks[wk];
  const nowDate = yy + "-" + mm + "-" + dd;
  const nowWeek = week;
  return nowDate + "  " + nowWeek;
}

function format(time: string, formats: string): string {
  const t = new Date(time);
  const tf = function (i: number): any {
    return (i < 10 ? "0" : "") + i;
  };
  return formats.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a: string) {
    switch (a) {
      case "yyyy":
        return tf(t.getFullYear());
      case "MM":
        return tf(t.getMonth() + 1);
      case "mm":
        return tf(t.getMinutes());
      case "dd":
        return tf(t.getDate());
      case "HH":
        return tf(t.getHours());
      case "ss":
        return tf(t.getSeconds());
    }
  });
}

export default {
  setNowTimes: setNowTimes,
  format: format,
};
