
import { defineComponent } from "vue";
import utils from "@/utils/utils";
import { NavBar } from "vant";
export default defineComponent({
  name: "Navbar",
  props: {
    search: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [NavBar.name]: NavBar,
  },
  setup() {
    const logoPic = "../assets/logo.png";
    var nowTime = utils.setNowTimes();

    return { logoPic, nowTime };
  },
});
